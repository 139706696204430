import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { CSSProperties, FC, memo, ReactNode } from "react";
import { Sticky, StickyContainer } from "react-sticky";
import { RelatedCasesDetailPageQuery } from "../../../graphql-types";
import Button from "../../components/Button";
import {
  MainContainer,
  PageContainer,
  SkewMainContainer,
} from "../../components/Container";
import Footer from "../../components/Footer";
import Header, { ThemeEnum } from "../../components/Header";
import { HtmlRender } from "../../components/HtmlRender";
import Metadata, { mapMetadata } from "../../components/Metadata";
import { PageTitle, SectionTitle } from "../../components/Title";
import { CustomerCase } from "../../images/customerCase";
import { useMediaScreen } from "../../utils/utils";
import LearnMoreView from "../../Views/LearnMoreView";
import {
  CustomerAvatar,
  CustomerInfo,
  RenderCustomerCases,
} from "../customer-case";
import { PageTitleWithIcon } from "../price";

const RenderList = ({
  title,
  list,
}: {
  title: string;
  list: { title: string }[];
}) => {
  return (
    <div className="border-t px-5 py-4">
      <h5 className="text-xs text-[#666] mb-3">{title}</h5>
      {list.map(({ title }) => (
        <div className="flex items-center mb-1 last-child-margin-none">
          <div className="w-1.5 h-1.5 rounded-full bg-black mr-2 " />
          <p>{title}</p>
        </div>
      ))}
    </div>
  );
};

const RelatedCasesDetailPage: FC<{
  data: RelatedCasesDetailPageQuery;
}> = ({ data: { contentfulCaseStudy } }) => {
  const { t } = useTranslation();
  const { isSmScreen } = useMediaScreen();
  return (
    <PageContainer>
      <Header defaultTheme={ThemeEnum.dark} />
      <Metadata {...mapMetadata(contentfulCaseStudy.metadata)} />
      <MainContainer>
        <PageTitleWithIcon title={t("客户案例")} icon={<CustomerCase />} />
        <div className="grid grid-cols-1 sm:grid-cols-10 gap-16 border-b pb-14 border-opacity-50">
          <div className="sm:col-span-7">
            <PageTitle className="mb-8">{contentfulCaseStudy?.title}</PageTitle>
            <p className="text-color-blue-gary leading-6">
              {contentfulCaseStudy?.describe?.describe}
            </p>
          </div>
          {/* <div className="sm:col-span-3 light-gray-bg shadow-offset-20 pt-8 pb-3 rounded-lg">
            <div className="flex justify-center items-center px-5 pb-7">
              <ContentfulImage image={contentfulCaseStudy?.companyLogo!} />
            </div>
            <RenderList
              title={t("服务场景")}
              list={[{ title: contentfulCaseStudy?.servicesScenario?.title }]}
            />
            <RenderList
              title={t("技术方案")}
              list={contentfulCaseStudy?.relatedTechnology ?? []}
            />
          </div> */}
        </div>
        <StickyContainer className="grid grid-cols-1 gap-8 sm:flex mt-12 pb-12">
          {/* <div className="sm:mr-20 sm:w-[274px]">
            {isSmScreen ? (
              <ExtraInfoWidthSticky>
                <ExtraInfo lists={contentfulCaseStudy?.extraInfo ?? []} />
              </ExtraInfoWidthSticky>
            ) : (
              <div>
                <ExtraInfo lists={contentfulCaseStudy?.extraInfo ?? []} />
              </div>
            )}
          </div> */}
          <div className="flex-1">
            <HtmlRender html={contentfulCaseStudy?.detailHtml?.detailHtml!} />
            <div className="light-gray-bg w-full rounded-lg">
              {(contentfulCaseStudy.customer ?? []).map(
                (item: CustomerInfo) => (
                  <div className="p-6">
                    <CustomerAvatar {...item} />
                    <p className="leading-8 mt-2">"{item.words}"</p>
                  </div>
                )
              )}
            </div>
          </div>
        </StickyContainer>
      </MainContainer>
      {contentfulCaseStudy?.related_cases && (
        <SkewMainContainer
          hideTop
          fillClassName="light-gray-f6-bg"
          className="z-30"
        >
          <SectionTitle className="mb-8">{t("更多客户案例")}</SectionTitle>
          <RenderCustomerCases
            relatedCases={contentfulCaseStudy?.related_cases ?? []}
          />
        </SkewMainContainer>
      )}

      <LearnMoreView
        leftUrl={"/contact"}
        leftText={t("填写需求表格")}
        rightText={t("了解价格")}
        rightUrl={"/price"}
      />
      <Footer />
    </PageContainer>
  );
};

const ExtraInfo = ({
  lists,
}: {
  lists: { title: string; desc: { desc: string } }[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {lists.map(({ title, desc }) => (
          <div className="mb-12">
            <h5 className="text-xl font-bold">{title}</h5>
            <p className="mt-1 text-color-blue-gary">{desc.desc}</p>
          </div>
        ))}
      </div>
      <div>
        <h3 className="text-xl font-bold mb-3">{t("准备好开始了？")}</h3>
        <Button onClick={() => navigate("/contact")}>{t("联系销售")}</Button>
      </div>
    </>
  );
};

const ExtraInfoWidthSticky = memo((props: { children: ReactNode }) => {
  return (
    <Sticky>
      {({ style }: { style: CSSProperties }) => {
        const { top, ...otherStyle } = style;
        return (
          <div style={{ ...otherStyle, top: 96 }} className="extra-info-width">
            {props.children}
          </div>
        );
      }}
    </Sticky>
  );
});

export default RelatedCasesDetailPage;

export const query = graphql`
  query RelatedCasesDetailPage($slug: String!, $language: String!) {
    ...AllLangauages
    contentfulCaseStudy(slug: { eq: $slug }, node_locale: { eq: $language }) {
      slug
      title
      metadata {
        ...MetadataInfo
      }
      description {
        description
      }
      servicesScenario {
        slug
        title
      }
      relatedTechnology {
        slug
        title
      }
      companyLogo {
        ...ContentfulImage200x200
      }
      detailHtml {
        detailHtml
      }
      extraInfo {
        title
        desc {
          desc
        }
      }
      related_cases {
        slug
        title
        preview_logo {
          ...ContentfulImage200x200
        }
        customer {
          words
          companyName
          position
          avatar {
            ...ContentfulImage200x200
          }
          name
        }
      }
      customer {
        words
        companyName
        position
        avatar {
          ...ContentfulImage200x200
        }
        name
      }
    }
  }
`;
